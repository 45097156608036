import React from "react"
import { graphql } from "gatsby"

import EventListContent from "./event-list-content"

const EventCategory = props => <EventListContent {...props} />

export const query = graphql`
  query ($categorySlug: String!, $skip: Int!, $limit: Int!, $market: String!) {
    sanityEventCategory(slug: { current: { eq: $categorySlug } }) {
      title
      subtitle
      seo {
        canonical
        description
        metaTitle
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
        removeSitemap
      }
    }
    sanityEventListing(tabs: { content: { market: { eq: $market } } }) {
      tabs {
        content {
          hero
          subtitle
          text: _rawText
        }
      }
    }
    allSanityEvent(
      filter: {
        tabs: {
          content: {
            market: { eq: $market }
            type: { ne: "previous" }
            category: { slug: { current: { eq: $categorySlug } } }
          }
        }
      }
      sort: { fields: tabs___content___eventDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        tabs {
          content {
            title
            isEventLink
            eventLocation
            boothNumber
            subtitle
            _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
            category {
              slug {
                current
              }
            }
            slug {
              current
            }
            excerpt
          }
        }
      }
    }
    allSanityEventCategory(filter: { market: { eq: $market } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`

export default EventCategory
